import axios from "axios";

const BASE_API_URL = "https://beyond-code-api.onrender.com"
const DEV_API_URL = "http://localhost:5000"

export const API_URL = (process.env.REACT_APP_ENV === 'production') ? BASE_API_URL : DEV_API_URL;

export async function isUserPremium(userId: string) {
    try {
        const response = await axios.post(`${API_URL}/auth/check-premium-status/`, {userId});
        return response.data.isPremium
    } catch (error) {
        console.error("Error fetching prices:", error);
    }
}


export async function getPricingOptions() {
    try {
        return await axios.get(`${API_URL}/stripe/pricing-options/`);
    } catch (error) {
        console.error("Error fetching prices:", error);
    }
}

export async function validateCoupon(code: String) {
    try {
        return await axios.post(`${API_URL}/stripe/validate-coupon/`, {code});
    } catch (error) {
        console.error("Error validating coupon:", error);
    }
}

export async function createNewCheckoutSession(option: string, coupon: string, successUrl: string, cancelUrl: string, uid: string, email: string) {
    try {
        return await axios.post(`${API_URL}/stripe/create-checkout-session/`, {
            option: option,
            coupon: coupon,
            successUrl: successUrl,
            cancelUrl: cancelUrl,
            uid: uid,
            email: email
        });
    } catch (error) {
        console.error("Error creating checkout session:", error);
    }
}