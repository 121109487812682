import React from 'react';
import '../css/LearningPathContent.css'
import {ICONS} from "../util/IconHelper";
import {useNavigate} from "react-router-dom";
import {Advice} from "../models/Advice";
import {AdviceSection} from "./AdviceSection";
import {trackEvent} from "../util/TrackingHelper";

interface LearningPathContentProps {
    learningPath: {
        courseHeader: string;
        courses: any [];
        interviewHeader: string;
        interviews: any [];
        resourceHeader: string;
        resources: any [];
        adviceHeader: string;
        advice: Advice[];
        articlesHeader: string;
        articles: any [];
        navUrl: string;
        pageTracking: string;
    }
}

export const LearningPathContent: React.FC<LearningPathContentProps> = ({learningPath}) => {
    return (
        <div className="learning-path-content-container">
            <h3 className="learning-path-header">{learningPath.courseHeader}</h3>
            <div className="learning-path-container courses">
                {learningPath.courses.map((course, index) => (
                    <LearningGridItem key={index} item={course} type="courses" navUrl={learningPath.navUrl} page={learningPath.pageTracking} typeTracking="Course"/>
                ))}
            </div>
            <h3 className="learning-path-header">{learningPath.interviewHeader}</h3>
            <div className="learning-path-container interviews">
                {learningPath.interviews.map((interview, index) => (
                    <LearningGridItem key={index} item={interview} type="interviews" navUrl={learningPath.navUrl} page={learningPath.pageTracking} typeTracking="Interview"/>
                ))}
            </div>
            <h3 className="learning-path-header">{learningPath.resourceHeader}</h3>
            <div className="learning-path-container resources">
                {learningPath.resources.map((resource, index) => (
                    <LearningGridItem key={index} item={resource} type="resources" navUrl={learningPath.navUrl} page={learningPath.pageTracking} typeTracking="Resource"/>
                ))}
            </div>
            <h3 className="learning-path-header">{learningPath.adviceHeader}</h3>
            <AdviceSection adviceList={learningPath.advice} page={learningPath.pageTracking}/>
            <h3 className="learning-path-header">{learningPath.articlesHeader}</h3>
            <div className="learning-path-container articles">
                {learningPath.articles.map((article, index) => (
                    <LearningGridItem key={index} item={article} type="articles" navUrl={learningPath.navUrl} page={learningPath.pageTracking} typeTracking="Article"/>
                ))}
            </div>
        </div>
    );
};

const LearningGridItem: React.FC<any> = ({item, type, navUrl, page, typeTracking}) => {
    const navigate = useNavigate();
    let navigateUrl = `${navUrl}${type}/${item.navUrl}`;

    const handleClick = () => {
        navigate(navigateUrl);
        trackEvent({event: `${typeTracking}_${item.trackingId}`, page: page})
    }
    return (
        <div className={`grid-item-container ${type}`} onClick={handleClick}>
            <img src={item.thumbnail} alt={`${item.name} icon`}/>
            <div className={"grid-item-info-container"}>
                <h4 className={"learning-grid-item-header"}>{item.name}</h4>
                {(type === "courses" || type === "articles") && (
                    <p className={"learning-grid-item-description"}>{item.description}</p>
                )}
                <div className={"learning-grid-item-icons-list-container"}>
                    {(type === "interviews" || type === "courses") &&
                        <GridItemIconInfo icon={ICONS.CLOCK} text={item.time}/>}
                    {type === "courses" && <GridItemIconInfo icon={ICONS.LESSONS} text={`${item.numberOfLessons} Lessons`}/>}
                    {type === "resources" && <GridItemIconInfo icon={ICONS.PDF} text="Download PDF"/>}
                    {type === "articles" && <GridItemIconInfo icon={ICONS.CALENDAR} text={item.date}/>}
                </div>
            </div>
        </div>
    )
}

export const GridItemIconInfo: React.FC<any> = ({icon: Icon, text}) => {
    return (
        <div className={"learning-grid-item-icon-container"}>
            <Icon />
            <h5>{text}</h5>
        </div>
    )
}