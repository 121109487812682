export abstract class GenericContent {
    name: string;
    description: string;
    thumbnail: string;
    navUrl: string;
    trackingId: string;

    protected constructor(name: string, description: string, thumbnail: string, navUrl: string, trackingId: string) {
        this.name = name;
        this.description = description;
        this.thumbnail = thumbnail;
        this.navUrl = navUrl;
        this.trackingId = trackingId;
    }
}