import React, {useEffect, useState} from 'react';
import "../css/GenericPage.css"
import "../css/PremiumOverlay.css"
import {PREMIUM_PERKS} from "../constants/PricingConfig";
import {ICONS} from "../util/IconHelper";
import {createNewCheckoutSession, getPricingOptions, validateCoupon} from "../util/APIHelper";
import {createCheckoutSession} from "../util/Stripe";
import {OrbitProgress} from "react-loading-indicators";

export const PremiumOverlay: React.FC<any> = ({user, setShowPremiumOverlay}) => {
    const [showCouponCode, setShowCouponCode] = React.useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [approvedCouponCode, setApprovedCouponCode] = React.useState("");
    const [pricingOptions, setPricingOptions] = React.useState([]);
    const [couponText, setCouponText] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    const checkCoupon = () => {
        setCouponText("Loading...")
        validateCoupon(couponCode.toUpperCase()).then(response => {
            if (response && response.data['isValid']) {
                setCouponText("Coupon Applied!")
                setApprovedCouponCode(response.data['coupon'])
                setPricingOptions(response.data['updatedPricingOptions'])
            } else {
                setCouponText("Coupon Invalid")
            }
        })
    }

    const onOptionClicked = (optionId: string) => {
        setLoading(true)
        createNewCheckoutSession(
            optionId,
            approvedCouponCode,
            window.location.origin,
            window.location.origin,
            user.uid,
            user.email
        ).then(response => {
            if (response) {
                createCheckoutSession(response.data.sessionId)
            }
        })
    }

    useEffect(() => {
        getPricingOptions().then((response) => {
            if (response) {
                setPricingOptions(response.data['pricingOptions'])
                setLoading(false)
            }
        }).catch((error) => {
            console.error("Error fetching pricing options:", error);
        });
    }, []);

    return (
        <div className={'overlay-container'} onClick={() => setShowPremiumOverlay(false)}>
            <div className={`overlay-content premium-overlay-content ${loading ? 'loading-content' : ''}`}
                 onClick={(e) => e.stopPropagation()}>
                <div className={'premium-options-container left'}>
                    <h4>Let's Get Started!</h4>
                    <h5>We’ve got a plan for your budget</h5>
                    <div className={'premium-option-choices-container'}>
                        {pricingOptions.map((item) => (
                            <PremiumOption pricingOption={item} user={user} onOptionClicked={onOptionClicked}/>
                        ))}
                    </div>
                    <div className={'coupon-container'}>
                        {!showCouponCode && <div onClick={() => setShowCouponCode(true)}>Have a Coupon?</div>}
                        {showCouponCode &&
                            <>
                                <input
                                    className={'coupon-code-input'}
                                    maxLength={15}
                                    onChange={(e) => setCouponCode(e.target.value)}
                                />
                                <div className={'coupon-submit-right-arrow-container'} onClick={() => checkCoupon()}>
                                    <ICONS.RIGHT_ARROW className={'coupon-submit-right-arrow'} />
                                </div>
                                {couponText && <p>{couponText}</p>}
                            </>
                        }
                    </div>
                </div>
                <div className={'premium-options-container right'}>
                    <h5>What You Get</h5>
                    <div className={'premium-options-list-container'}>
                        {PREMIUM_PERKS.map((item) => (
                            <PremiumIncludeLine item={item}/>
                        ))}
                    </div>
                </div>
                <ICONS.CLOSE className={'premium-options-close'}/>
            </div>
            {loading && <div className={'loading-spinner-container'}>
                <OrbitProgress color="#006ff0" size="medium" text="" textColor="" />
            </div>}
        </div>
    );
};

const PremiumOption: React.FC<any> = ({user, pricingOption, onOptionClicked}) => {
    return (
        <div className={'premium-option-container'} onClick={() => onOptionClicked(pricingOption.stripeId)}>
            {pricingOption.recommended && (
                <div className={'premium-option-recommended-container'}><p>SAVE 50%</p></div>
            )}
            <p className={'premium-option-price'}><span className={"premium-option-price-amount"}>${pricingOption.price}</span>/ per {pricingOption.interval}</p>
            <ICONS.RIGHT_ARROW className={'premium-option-right-arrow'} />
        </div>
    )
}

const PremiumIncludeLine:React.FC<any> = ({item}) => {
    return (
        <div className={'premium-includes-container'}>
            <ICONS.CHECKMARK_CIRCLE className={'premium-includes-checkmark'}/>
            <div className={'premium-includes-text-container'}>
                <h6><span className={'premium-includes-num'}>{item.number}</span> {item.text}</h6>
                <p>{item.subtitle}</p>
            </div>

        </div>
    )
}