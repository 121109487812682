import AboutMeAvatar from "../images/Avatar Blue Background.webp"
import LinkedInImage from "../images/aboutMe/LinkedIn-Logo.webp"
import HomeDepotImage from "../images/aboutMe/Home-Depot-Logo.webp"
import DeltaImage from "../images/aboutMe/Delta-Logo.webp"
import FinTechImage from "../images/aboutMe/Brightwell-logo.webp"
import DoDImage from "../images/aboutMe/Department-of-Defense-Logo.webp"
import TechnicalSchoolImage from "../images/aboutMe/Technical-School.webp"
import AppDevInternshipImage from "../images/aboutMe/App-Development-Internship.webp"

export const ABOUT_ME = {
    "header": "Hey! I'm David",
    "paragraph": "I’ve been a programmer for 10 years, and I have a passion for helping people with their coding careers.\n" +
        "\n" +
        "I’ve worked all over the industry from small start ups to Big Tech. I currently work at LinkedIn where I help people look for their ideal job.\n" +
        "\n" +
        "I created Beyond Code in order to fill a gap I saw in the industry. There are a million courses teaching you how to code, but there is such little guidance on everything else: picking a specialty, learning certain concepts, writing a great resume, nailing your interviews, etc.\n" +
        "\n" +
        "My goal with Beyond Code is to simply teach you everything I wish I had known earlier in my career.",
    "image": AboutMeAvatar,
    "whyListenList": [
        {
            "place": "LinkedIn",
            "position": "Senior Software Engineer",
            "items": [
                "Actively working on a team to help people search for jobs",
                "Created features to help candidates without degrees land interviews",
                "After giving 50+ interviews, I was asked to design the interview prep material for incoming candidates"
            ],
            "date": "2021 - Present",
            "image": LinkedInImage
        },
        {
            "place": "The Home Depot",
            "position": "Senior Software Engineer - Contractor",
            "items": [
                "In response to COVID, worked with a small team that built and maintained Home Depot’s curbside application that allowed employees to safely help customers and which helped generate over $500,000,000 in revenue"
            ],
            "date": "2020",
            "image": HomeDepotImage
        },
        {
            "place": "Delta Airlines",
            "position": "Senior Software Engineer - Contractor",
            "items": [
                "Worked on an iOS and Android app that allowed over 30,000 monthly Delta workers (including pilots, flight attendants, flight control, etc) to message one another and maintain constant collaboration to successfully fly aircrafts"
            ],
            "date": "2019",
            "image": DeltaImage
        },
        {
            "place": "Small FinTech Company",
            "position": "Lead Android Developer",
            "items": [
                "Sole Android developer working on an app that provided payment solutions for over 80,000 cruise line migrant workers"
            ],
            "date": "2018 - 2019",
            "image": FinTechImage
        },
        {
            "place": "US Military",
            "position": "App Developer - Contractor",
            "items": [
                "App developer for a small team that provided ways for active military members to send photos/videos/etc back home to their families"
            ],
            "date": "2016 - 2018",
            "image": DoDImage
        },
        {
            "place": "Dropped out of school",
            "position": "",
            "items": [
                "After accepting my full time role with the US Government, I dropped out of school"
            ],
            "date": "2016",
            "image": TechnicalSchoolImage
        },
        {
            "place": "DevelAppMe",
            "position": "Mobile Developer Intern",
            "items": [
                "Interned at a 10 person start up that provided mobile app work to clients"
            ],
            "date": "2015",
            "image": AppDevInternshipImage
        },
        {
            "place": "Enrolled in Technical School",
            "position": "",
            "items": [
                "I began working towards a Computer Programming diploma"
            ],
            "date": "2014",
            "image": TechnicalSchoolImage
        }
    ]
}