import React from 'react';
import "../css/GenericPage.css";
import "../css/AccountPage.css";
import { getAuth, signOut } from "firebase/auth";
import {useNavigate} from "react-router-dom";

const STRIPE_BILLING_URL = "https://billing.stripe.com/p/login/6oE7vRdn7cFw5wI6oo"

export const AccountPage: React.FC = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                navigate("/")
            })
            .catch((error) => {
                console.error("Error signing out: ", error);
            });
    };

    return (
        <div className="account-container">
            <div className="account-content-container">
                <h4>Account Settings</h4>
                <a href={STRIPE_BILLING_URL}>Manage Account</a>
                <button onClick={handleLogout} className="account-logout-button">Logout</button>
            </div>
        </div>
    );
};