//src/auth/InitializeStripe.ts
import { loadStripe, Stripe } from "@stripe/stripe-js";

const STRIPE_KEY = "pk_live_51Q2gPWIke5nHJNVqb5d9QcFVCiGnQcqC97MF6cK76521XNxwGdiWGjzhCTj6chVaeIdUfOSxqLiZog8pbZwzwZjv00hSqZyCGw";

let stripePromise: Promise<Stripe | null> | null = null;

const initializeStripe = async (): Promise<Stripe | null> => {
    if (!stripePromise) {
        stripePromise = loadStripe(STRIPE_KEY);
    }

    return stripePromise;
};

export default initializeStripe;