import React from 'react';
import {Link, useParams} from "react-router-dom";
import 'video.js/dist/video-js.css';
import '../css/LessonPage.css'
import "../css/CoursePage.css";
import {Course, Lesson} from "../models/Course";
import { getContentFromParams } from "../util/ContentHelper";
import VideoPlayer from "../components/VideoPlayer";
import {ICONS} from "../util/IconHelper";

export const LessonPage: React.FC<any> = ({ isAuthenticated, isPremium, setShowAuth, setShowPremiumOverlay }) => {
    const { learningPath, course, sectionIdParam, lessonIdParam } = useParams();
    const sectionId = Number(sectionIdParam)
    const lessonId = Number(lessonIdParam)
    const pathString = learningPath ? learningPath : ""
    const courseData = getContentFromParams(pathString, 'courses', course) as Course;
    const baseUrl = `${learningPath}/courses/${course}/`
    if (!courseData) {
        return (<></>)
    }

    let lessonData: Lesson = courseData.sections[sectionId].lessons[lessonId];

    return (
        <div className="page-container">
            {lessonData && courseData && (
                <div className="lesson-page-container">
                    <LessonHeader learningPath={pathString} course={courseData} lessonName={lessonData.title} />
                    <VideoPlayer isPremium={isPremium} fileUrl={`courses/${lessonData.fileName}`} isAuthenticated={isAuthenticated} setShowAuth={setShowAuth} setShowPremiumOverlay={setShowPremiumOverlay}/>
                    <LessonFooter lessonData={lessonData} courseData={courseData} sectionId={sectionId} lessonId={lessonId} baseUrl={baseUrl}/>
                </div>
            )}
        </div>
    );
};

const LessonHeader: React.FC<{learningPath: string, course: Course, lessonName: string}> = ({ learningPath, course, lessonName }) => {
    return (
        <div className={'lesson-header-container'}>
            <nav className={'breadcrumb'}>
                <Link to={`/${learningPath}/courses/${course.navUrl}`}>
                    {course.name}
                </Link>
                {' > '}
                <span>{lessonName}</span>
            </nav>
        </div>
    );
};

const LessonFooter: React.FC<{ courseData: Course; lessonData: Lesson, sectionId: number, lessonId: number, baseUrl: string }> =
    ({ courseData, sectionId, lessonId, baseUrl }) => {
        let prevLesson: Lesson | null = null;
        let nextLesson: Lesson | null = null;
        let prevSectionId = sectionId;
        let prevLessonId = lessonId;
        let nextSectionId = sectionId;
        let nextLessonId = lessonId;

        const currentSection = courseData.sections[sectionId];
        const currentLessons = currentSection.lessons;

        if (lessonId > 0) {
            prevLesson = currentLessons[lessonId - 1];
            prevLessonId = lessonId - 1;
        } else if (sectionId > 0) {
            const previousSection = courseData.sections[sectionId - 1];
            prevLesson = previousSection.lessons[previousSection.lessons.length - 1];
            prevSectionId = sectionId - 1;
            prevLessonId = previousSection.lessons.length - 1;
        }

        if (lessonId < currentLessons.length - 1) {
            nextLesson = currentLessons[lessonId + 1];
            nextLessonId = lessonId + 1;
        } else if (sectionId < courseData.sections.length - 1) {
            const nextSection = courseData.sections[sectionId + 1];
            nextLesson = nextSection.lessons[0];
            nextSectionId = sectionId + 1;
            nextLessonId = 0;
        }

        return (
            <div className={'lesson-footer-container'}>
                {prevLesson && (
                    <a href={`/${baseUrl}${prevSectionId}/${prevLessonId}`}>
                        <div className={'lesson-footer-button prev'}>
                            <ICONS.LEFT_ARROW />
                            {prevLesson.title}
                        </div>
                    </a>
                )}
                {nextLesson && (
                    <a href={`/${baseUrl}${nextSectionId}/${nextLessonId}`}>
                        <div className={'lesson-footer-button next'}>
                            {nextLesson.title}
                            <ICONS.RIGHT_ARROW />
                        </div>
                    </a>
                )}
            </div>
        );
    };