import React from 'react';
import "../css/GenericPage.css"
import {LearningPathHeroImage} from "../components/LearningPathHeroImage";
import {LearningPathContent} from "../components/LearningPathContent";

interface LearningPathProps {
    isAuthenticated: boolean;
    isPremium: boolean;
    learningPath: any;
    setShowPremiumOverlay: any;
    setShowAuth: any;
}

export const LearningPathPage: React.FC<LearningPathProps> = ({isAuthenticated, isPremium, learningPath, setShowPremiumOverlay, setShowAuth}) => {
    return (
        <div className="page-container">
            <LearningPathHeroImage learningPath={learningPath} isPremium={isPremium} isAuthenticated={isAuthenticated} setShowPremiumOverlay={setShowPremiumOverlay} setShowAuth={setShowAuth}/>
            <LearningPathContent learningPath={learningPath} />
        </div>
    );
};