import {Advice, AdviceAnswer, AdvicePerson} from "../models/Advice";

const PEOPLE = {
    AUBREY_RHODES: new AdvicePerson("Aubrey Rhodes", "Chief Technology Officer", "Workful"),
    EILEEN_HO: new AdvicePerson("Eileen Ho", "Senior Frontend Software Engineer", "foodpanda"),
    KIMBERLY_BROWN: new AdvicePerson("Kimberly Brown", "Engineering Manager", "TestBox"),
    BRIAN_MORTON: new AdvicePerson("Brian Morton", "Owner", "Morton Software Insights"),
    JAMES_N: new AdvicePerson("James N", "Senior Software Engineer", "Clayton"),
    ANON_1: new AdvicePerson("", "Senior UI/UX Engineer", "Mid Sized Tech Company"),
    ANON_2: new AdvicePerson("", "Senior Cloud Infrastructure Engineer", "Large Distribution Tech Company"),
    ANON_3: new AdvicePerson("", "Senior Software Engineer", "LinkedIn"),
}

export const ADVICE = {
    becomingAProgrammer: [
        new Advice(
            "If you could go back to when you first started your career, what is one piece of advice you would give?",
            [
                new AdviceAnswer(
                    "Learn about algorithms, they actually matter. Don’t try to master them on day one, but don’t ignore them either.",
                    PEOPLE.BRIAN_MORTON
                ),
                new AdviceAnswer(
                    "I would have told myself that I needed to focus more on execution at a faster rate – I slowed myself down a lot because I would spend days finding all answers by myself, because I was convinced that I needed to prove myself and do it all on my own. Your team and mentors are there to help you – use them!",
                    PEOPLE.EILEEN_HO
                ),
                new AdviceAnswer(
                    "Being a female in tech I wish I would have spoken up more. It wasn’t until I had a great manager that saw my potential in me did I start to do that and my career has taken off a bit by then. People may think I’m a lot sometimes but others have said I have given them even more confidence than they had already. Being yourself is more important than fitting a mold.",
                    PEOPLE.KIMBERLY_BROWN
                )
            ],
            "FirstStartedCareerAdvice"
        ),
        new Advice(
            "What qualities do you think set successful software developers apart from those who struggle to find success in the industry?",
            [
                new AdviceAnswer(
                    "It all depends on the industry that you’re in. I have seen “”successful”” developers be tyrants in how code is built and deployed into the system they manage and it’s only their way or the highway. They have gotten high regards from management because they always deliver. I on the other hand don’t count those developers as the successful ones. I gauge the developers I have worked with as being successful when they are always learning more about their craft and then also sharing what they learn with those around them to help others get to their level. It’s a film my cup and let it runneth over into the next cup. I have always told people I know I am doing my job right when those that report to me can take my job from me. As a software developer the most successful developers are those that are fostering that relationship. You end up with more mutual respect and support across the board.",
                    PEOPLE.KIMBERLY_BROWN
                ),
                new AdviceAnswer(
                    "I think humility is a very important quality as an engineer; you will sometimes be wrong or not understand something, no matter how experienced you are. Being willing to ask for help, or to admit that you were wrong is very important not only to earn and maintain the respect of your peers, but also will accelerate your rate of learning.",
                    PEOPLE.JAMES_N
                ),
                new AdviceAnswer(
                    "You gotta keep being hungry to learn, if you stall you fall back while everyone moves forward",
                    PEOPLE.ANON_2
                )
            ],
            "WhatQualitiesSuccessfulDevs"
        ),
        new Advice(
            "I am about to begin learning my first programming language. Is there any advice you have with regards to the learning process?",
            [
                new AdviceAnswer(
                    "Learning to code was a highly contextual experience for me; the more I could relate the details of the language to real world scenarios, the easier it was to understand and remember the concepts. For this reason, one of the most helpful things that I did was simply to write an application, backend and frontend, for an idea that I had. This will seem scary at first, but breaking it down into small pieces that you can research (even with help from generative AI, these days) will make this possible; for example, if you're making a simple note-taking application, you'll have questions like, \"How can my notes stick around after I've closed my browser window?\", \"How do I authenticate with the app so that it knows to show me only my notes?\", \"How can I make this app available to people on the internet?\" This is what I mean by contextualization- they're all very-pertinent and important questions you may not have thought about when using Instagram but now you will need to learn how to answer, and actively trying to apply this when learning will accelerate the process.",
                    PEOPLE.JAMES_N
                ),
                new AdviceAnswer(
                    "Learn concepts and pseudo-code first. Don't worry about the language. Every language has the same basis of having to be able to break down and understand a problem at the granular level. If you can't breakdown a problem into tasks and individual components and can only see the larger picture as only one thing to solve you are going to have a really hard time being able to be truly successful as a software engineer.",
                    PEOPLE.KIMBERLY_BROWN
                ),
                new AdviceAnswer(
                    "Focus on a task you want to accomplish. One of my first programming languages was Python. Picking up the Python book felt like trying to boil the ocean since it's useful for so many tasks. When I decided to focus just on web apps with the Django framework I gained enough fundamentals to progress to learning the whole language.",
                    PEOPLE.BRIAN_MORTON
                )
            ],
            "FirstCodingLanguageLearning"
        ),
        new Advice(
            "What advice would you give someone considering software engineering as a career?",
            [
                new AdviceAnswer(
                    "Don't stress yourself out. Everyone makes mistakes and the biggest things we can do is learn from those mistakes. It is a rewarding and stressful career all at once. And don't worry about AI it will not take over your job.",
                    PEOPLE.KIMBERLY_BROWN
                )
            ],
            "ConsideringSoftwareAsCareer"
        ),
        new Advice(
            "What are some of the best ways for someone to learn to code on their own?",
            [
                new AdviceAnswer(
                    "\"There hasn't been a better time in our history to learn using various resources on the internet.\n" +
                    "\n" +
                    "Depending on what kind of learner you are, you have options:\n" +
                    "    - YouTube is pretty much filled with endless tutorials on various languages, frameworks, tools, etc. If you wanna get started with anything, start in YouTube.\n" +
                    "    - Even tho a lot of the content in YouTube can be pretty high quality, more often than not you can find more polished \"\"classes\"\" in places like Udemy or MasterClass.\n" +
                    "    - Tons of written articles and resources available, both paid and free.\n" +
                    "    - If you're a self starter, there are plenty of project ideas for beginners to get started with new languages. The very famous \"\"TODO App\"\" has been written and re-written in almost every language out there, and someone usually has documented this process.\n" +
                    "    - Reddit and Stack Overflow can be good resources to ask questions and get solid answers.\n" +
                    "    - Google is your friend, use it!\n",
                    PEOPLE.ANON_1
                ),
                new AdviceAnswer(
                    "There are so many free resources to be able to learn to code in almost any language. A good way to learn to code is to think of a problem you have and code to solve it. You end up more invested in the outcome and it causes you to break out your critical thinking skills vs just following a tutorial that gives you every step.",
                    PEOPLE.KIMBERLY_BROWN
                )
            ],
            "BestWaysToLearnOnOwn"
        ),
        new Advice(
            "I’ve been working in a completely unrelated field for years, and I am ready for a change. Is it too late for me to transition into a career as a software engineer?",
            [
                new AdviceAnswer(
                    "It's never too late to change. Whatever field you are in there is always a spot in tech for you. It may not be as a software engineer at first but you can pivot into it by learning the ropes. There are analyst jobs, support roles, QA testers, and product roles. A lot of these roles require no coding knowledge up front but as you move up within in them you become more exposed to the engineers and can start to make your move.",
                    PEOPLE.KIMBERLY_BROWN
                ),
                new AdviceAnswer(
                    "Never, often you can take some aspects of your current work into software design",
                    PEOPLE.ANON_2
                )
            ],
            "UnrelatedFieldReadyForChange"
        ),
        new Advice(
            "Do you think a college education is necessary for someone coming out of High School and looking to get into software engineering?",
            [
                new AdviceAnswer(
                  "Some of the best developers I have worked with transferred from other industries. Being able to combine the skills they learned in their previous fields with software development skills gave them a leg up in the long run. The transition can be a lot of work, but those who do it can go on to have very successful careers in tech.",
                  PEOPLE.AUBREY_RHODES
                ),
                new AdviceAnswer(
                    "College education can be important or not, depending on the person. If you are able to be self taught, don't mind doing lots of reading and are disciplined, there are a plethora of resources available in today's internet that could help you in your learning journey and set you up for an easy entry level position.\n" +
                    "Like with anything: the more time you spend on improving your knowledge and skills, the more marketable you become.\n" +
                    "If you're not a good self-starter, college can be a good route to take since it's a more controlled and structured environment.",
                    PEOPLE.ANON_1),
                new AdviceAnswer(
                    "Strictly speaking, a college degree is not needed to get to almost any level in tech that you want to; that said, the amount of effort you need to put in may be comparable, as there is a vast amount of knowledge out there. When trying to get into your first job, I recommend targeting a very specific subset of tech jobs to start off with- Junior Web Developer, for example. In this case I would compile a list of expectations from job descriptions for this position and go as deep as you can on each item.",
                    PEOPLE.JAMES_N
                ),
                new AdviceAnswer(
                    "I don't think it is necessary at all to get a degree to get into software engineering but it does help. You can teach yourself the hard skills on your own but software engineering is much more than being able to code. There are so many soft skills that you learn through working on projects throughout school. You could skip the degree course and opt for a bootcamp like study if you want. ",
                    PEOPLE.KIMBERLY_BROWN
                ),
                new AdviceAnswer(
                    "No. I think it can be helpful later to distinguish yourself from other applicants and provide the skills necessary to advance beyond hands-on engineering such as management or ownership. When you are just getting started it is absolutely not necessary in my opinion, your skills and aptitude are far more important.",
                    PEOPLE.BRIAN_MORTON
                ),
                new AdviceAnswer(
                    "I fundamentally don't believe that a formal education is a requirement to become a successful software engineer - you have to know how you learn best. For some people, that is within a traditional classroom setting, for others it's with hands on work, for others it's self-learning. However, one important thing to consider is how much tech recruiters still currently value formal education - it is significantly harder to get an internship or a job interview if you don't have a B.A. or M.A in an engineering field. So I would just say that this is still an important thing to consider when you are making the decision of how you want to get into software engineering. Another thing is that in big companies, they may still have a minimum education requirement for HR reasons. So not having a college degree might mean that they can't hire you for a specific role yet, or you have to have more years of work experience first before they will consider hiring you.",
                    PEOPLE.EILEEN_HO
                )
            ],
            "TransferredFromOtherIndustries"
        ),
        new Advice(
            "What are some of the biggest mistakes you've seen junior developers make, and how can they avoid them?",
            [
                new AdviceAnswer(
                    "I’ve seen a lot of junior developers struggle when they don’t ask questions and don’t ask for help when they get stuck. Your team will expect you to reach out when you need help. Learning how to get help quickly when you need it is one of the most important skills when you’re starting out. ",
                    PEOPLE.AUBREY_RHODES
                ),
                new AdviceAnswer(
                    "Learning how to ask questions. For some people, it means that they don't know how to ask questions and will waste too much time trying to find answers on their own. The key to early success is finding the way to be most efficient in the execution of your work - of course we all want to learn things on our own, but we also need to recognize how to unblock ourselves so that we can get tasks done in a timely manner. Your team needs to be able to rely on you to do the work and to get it done when you say it will. So ask questions when you need to - and find a wide range of resources to ask from (i.e. don't keep going to the same engineer over and over with the same questions). On the other end of the spectrum, don't ask too many questions if you haven't put in initial work. When I was a teacher, I used to tell my students that they had to tell me 3-5 things that they did to first try to answer a question themselves. Make sure you put in the bare minimum before asking questions to your team - no one wants to be bombarded with questions that are already answered in your team's docs, or are easily discovered on stack overflow, etc.",
                    PEOPLE.EILEEN_HO
                )
            ],
            "BiggestMistakesJuniorDevsMake"
        ),
        new Advice(
            "How has the software development industry changed since you started your career, and what do you think the future holds for it?",
            [
                new AdviceAnswer(
                    "I think it has gotten more traction because of the lucrative aspect of the industry and the culture around tech companies. More people are gravitating to it because of the perks that can come with the roles. With those perks those there comes that \"dark side\" of long hours and crunch time a lot of the time. I personally don't know what the future holds for it. As technology advances and AI comes more into play I think it can get more exciting. I don't think AI will take over software jobs. There is too much human interaction and emotion that molds requirements that AI just can't understand.",
                    PEOPLE.KIMBERLY_BROWN
                ),
                new AdviceAnswer(
                    "Everything has shifted from waterfall to agile. While I think there's a great deal to be learned from this methodology, I don't see it as a one-size-fits-all solution. Estimates and breakdowns are important, but spending too much time on ceremonies and meetings is an anti-pattern. Engineers are like free-range animals, we need time and space to accomplish work. If you feel like you're tripping over meetings while trying to get \"in the zone\" there's a problem. The very best stories involve a correct up-front design followed by the breakdown and estimation. Without informed design, you're right back to iterating waterfall style and blowing through dates.",
                    PEOPLE.BRIAN_MORTON
                )
            ],
            "HowHasIndustryChanged"
        )
    ],
    programmerResumes: [],
    techInterviews: [
        new Advice(
            "I'm trying to apply for entry level jobs, but they all require experience. Do you have any advice on how to show experience in order to land my first job?",
            [
                new AdviceAnswer(
                    "I got rejected 99 times before finally getting accepted once by a tiny no-name company.  Apply 100 times.  If you get rejected from ALL of them, then you're not even ready to start applying and need to re-assess how capable you think you are.",
                    PEOPLE.ANON_3
                ),
                new AdviceAnswer(
                    "Experience does not have to come from a full time developer role. Building a tool to help your team in your current position or building projects for clients part time on the side can give you enough experience for your first full time role.",
                    PEOPLE.AUBREY_RHODES
                ),
                new AdviceAnswer(
                    "Don't stress first of all. They say they all require experience but you may have experience from other roles that you can correlate to the roles directly that maybe aren't engineering related on the surface. If you are struggling to find a job without experience look into a passion project or reach out to local small businesses and offer to help them to amp up their website and/or ordering system. A lot of small businesses have basic websites because they are not tech savvy. So they will gladly allow you to help them. The pay might not be the greatest but it gains experience. Also use your network. Ask for opportunities through people you know. Networking is your biggest tool that is usually the least utilized.",
                    PEOPLE.KIMBERLY_BROWN
                ),
                new AdviceAnswer(
                    "Write a lot of code! Make your own projects and continuously maintain them and update them, using interesting and new technologies to show that you are always learning. Give links to your websites, apps, and github with specific code examples. Make sure that you've caught all bugs for your projects. One of my new favorite ways to come up with projects is just to join some online hackathons - there are some fun prompts, you have a deadline so you might be more likely to finish your project, and you'll definitely learn something new along the way! Plus if you win anything, you can add that to your resume :)",
                    PEOPLE.EILEEN_HO
                )
            ],
            "ShowExperienceForFirstJob"
        ),
        new Advice(
            "What are some non-technical skills that are important for software developers to have, and how can someone develop them?",
            [
                new AdviceAnswer(
                    "Communication and teamwork.  Be able to learn from others.  Don't let your ego prevent you from asking others for help and learning from the experience.",
                    PEOPLE.ANON_3
                ),
                new AdviceAnswer(
                    "Soft skills in communication are key. You don't only work with software developers. You will end up working with product mangers, quality assurance engineers, UI/UX designers, scrum masters, project managers and so many more individuals. All of these different people speak different languages and hold different roles in the software development lifecycle. The better you can communicate with all of them on why you think something should be done a certain way, why you changed it to do something else, or even just asking clarifying questions on a design can drastically help you. ",
                    PEOPLE.KIMBERLY_BROWN
                )
            ],
            "NonTechnicalSkillsForDevs"
        ),
        new Advice(
            "What do you think are the most important qualities for a software development team to have in order to be successful?",
            [
                new AdviceAnswer(
                    "Trust is a big part of what makes developer teams successful. If team members can bring up problems and trust that they will be heard and addressed, the team is able to fix issues much more quickly. ",
                    PEOPLE.AUBREY_RHODES
                ),
                new AdviceAnswer(
                    "Trust and good communication.  Trust your teammates are doing the best to their ability.  Communicate when you think a teammate is incorrect.  I have honest and candid opinions with the teammates i work best with.",
                    PEOPLE.ANON_3
                )
            ],
            "MostImportantQualitiesForDevTeam"
        ),
        new Advice(
            "What are some effective ways to stand out during a job interview for a software development position?",
            [
                new AdviceAnswer(
                    "Try to talk through problems with the interviewer. Even if you don't know the answer, you should make an effort to convey your intelligence and what you do know. Explain your thought process when doing whiteboarding problems and if you get stuck, don't be afraid to ask for help- often times interviewers want to know that candidates are willing to collaborate. With the understanding that an interview is not one-sided, don't be afraid to ask questions to the interviewer.",
                    PEOPLE.JAMES_N
                )
            ],
            "StandOutInJobInterview"
        ),
        new Advice(
            "What are some of the most common mistakes that candidates make during the interview process?",
            [
                new AdviceAnswer(
                    "Try to talk through problems with the interviewer. Even if you don't know the answer, you should make an effort to convey your intelligence and what you do know. Explain your thought process when doing whiteboarding problems and if you get stuck, don't be afraid to ask for help- often times interviewers want to know that candidates are willing to collaborate. With the understanding that an interview is not one-sided, don't be afraid to ask questions to the interviewer.",
                    PEOPLE.JAMES_N
                ),
                new AdviceAnswer(
                    "I’ve seen a lot of candidates fail interviews because they weren’t getting specific enough with their answers. The interviewer wants to see that you know the details of what you worked on in the past. Don’t be afraid to dive into the weeds when you are talking about past projects. ",
                    PEOPLE.AUBREY_RHODES
                ),
                new AdviceAnswer(
                    "The biggest mistake that junior developers make that I have seen is simply not admitting that they have made a mistake. There are so many developers in the world that go by the school books they learned from they are almost blind to how fast technology evolves. It's ok to make a mistake or use out of date concepts and then admit that you didn't know something. Good senior developers are there to help you grow into a senior developer over time. They should always willing to help you understand more with time. It's ok to make mistakes we all do. Just admit that you made one or maybe you don't know how to do something vs just trying to fix the problem yourself.",
                    PEOPLE.KIMBERLY_BROWN
                )
            ],
            "CommonMistakesCandidatesMakeInterviewing"
        ),
        new Advice(
            "From a manager's perspective, what makes someone a successful employee?",
            [
                new AdviceAnswer(
                    "What makes someone a successful employee is they are meeting their goals they have set for themselves along with you. They are encouraging and helping others. And enjoy what they are doing. ",
                    PEOPLE.KIMBERLY_BROWN
                )
            ],
            "ManagersPerspectiveWhatMakesSuccessfulEmployee"
        ),
        new Advice(
            "What are some of your favorite questions to ask/be asked during interviews?",
            [
                new AdviceAnswer(
                    "I ask two questions always. \n" +
                    "\n" +
                    "What is your favorite language and why? (I don't care how they answer this, there isn't a wrong answer. I just want to see how enthusiastic they are about something they enjoy.)\n" +
                    "What is your biggest oops moment and how did you recover from it? I'll tell you mind first. (Everyone makes mistakes being able to admit that you can overcome obstacles is one of the things I'm looking for.)\n",
                    PEOPLE.KIMBERLY_BROWN
                ),
                new AdviceAnswer(
                    "Security! Good security involves in-depth protection, and that starts with secure coding practices and designs. A little knowledge here can save lots of time and money later.",
                    PEOPLE.BRIAN_MORTON
                )
            ],
            "FavoriteQuestionsToAskDuringInterviews"
        ),
        new Advice(
            "What qualities do you think differentiate a successful manager from an unsuccessful one?",
            [
                new AdviceAnswer(
                    "A successful manager is intentional in creating a team culture that will enable all engineers to be successful. She is a good communicator and has early awareness of problems so that she can help work on resolutions before things get out of hand. She is always thinking ahead – thinking about the team’s growth in terms of projects and work, and also knows every team member’s career goals so that she can help them reach those goals.",
                    PEOPLE.EILEEN_HO
                ),
                new AdviceAnswer(
                    "Being able to sit in the trenches. The best managers will act as a player-coach, which often means pair programming or splitting up work. If the manager can’t play because of many years off the field, it diminishes their ability to teach and relate.",
                    PEOPLE.BRIAN_MORTON
                ),
            ],
            "WhatQualitiesDifferentiateSuccessfulManager"
        ),
        new Advice(
            "Have you ever interviewed a candidate that stood out more than anyone else? If so, why was that?",
            [
                new AdviceAnswer(
                    "Candidates that have stood out to me are ones that have adapted very quickly on the spot to feedback during the interview, and showed an ability to learn quickly. When I'm interviewing a candidate, I'm thinking \"would I want to work with this person on my team.\" This is way more important to me than whether or not the actual solution is correct.",
                    PEOPLE.EILEEN_HO
                )
            ],
            "CandidateThatStoodOut"
        )
    ]
};