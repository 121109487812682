import {LEARNING_PATHS} from "../constants/LearningPathsConfig";
import {GenericContent} from "../models/GenericContent";

export function getContentFromParams(
    learningPath: string | undefined,
    contentType: string | undefined,
    contentName: string | undefined
): GenericContent | null {
    const selectedLearningPath = LEARNING_PATHS.find(path => path.navUrl === `/${learningPath}/`);
    switch (contentType) {
        case 'courses':
            const courseData = selectedLearningPath?.courses.find(c => c.navUrl === `${contentName}`);
            return courseData || null
        case 'interviews':
            const interviewData = selectedLearningPath?.interviews.find(i => i.navUrl === `${contentName}`);
            return interviewData || null
        case 'resources':
            const resourceData = selectedLearningPath?.resources.find(r => r.navUrl === `${contentName}`);
            return resourceData || null
        case 'articles':
            const articleData = selectedLearningPath?.articles.find(a => a.navUrl === `${contentName}`);
            return articleData || null
    }
    return null
}