import {LOGOS} from "./LogosConfig";

export const FOOTER_CONFIG = {
    "logo": LOGOS.MEDIUM,
    'contactUsHeader': 'Contact Us',
    'contactUsContent': 'dthurman@beyondcode.app',
    'allRights': "All rights reserved © Beyond Code 2024",
    'privacyText': "Privacy Policy",
    'privacyUrl': 'https://app.termly.io/document/privacy-policy/4f05832b-52e7-44ea-b9fc-1fa34c57018d',
    'termsText': "Terms & Conditions",
    'termsUrl': 'https://app.termly.io/document/terms-of-service/970935f8-1c77-4aaa-a619-2f9137e5d37d'
}