export const AIS_IMPACT_ON_DEVELOPERS_CONTENT: string = `
    <p>It’s no secret: AI is currently changing our world. And with these changes, I hear one question over and over again..</p>
    <p>“Will AI replace software developers?”</p>
    <p>In order to answer that, we need to talk horses.</p>
    
    <h2>Horses?</h2>
    <p>The year is 1900. You’ve been happily running your horse carriage business. In fact, your family has been in the field for decades, and everything is great. That is, until this shmuck named Henry comes around.</p>
    <p>Henry slaps a few wheels on a metal box and sells his new “automobiles”. At first you scoff at the idea (after all, who doesn’t love a city run on constantly excreting horses). But soon Henry starts getting some traction with his invention. Suddenly you start to worry…</p>
    <p><i>Are horses going to be replaced?</i></p>
    
    <h2>The Options</h2>
    <p>So clearly in this metaphor, programmers are the doomed horse carriage owners who now fret over the AI automobiles. We’ve realized our field is about to be permanently changed. At this point, we’ve got two main options:</p>
    <p>1. Give up, shake our first at the sky, and spend the rest of our days cursing the artificially intelligent Henry Ford</p>
    <p>2. Realize that your experience in the businesses can be a huge asset. Sure the industry might be changing, but our experience can set us up for major success if we transition with it</p>
    
    <h2>AI</h2>
    <p>Alright look, nobody knows how AI is going to transform our next few years (let alone decades).</p>
    <p>Will certain jobs become outdated just like our once thriving horse carriage business? Undoubtedly. But that doesn’t mean the industry is doomed. It just means that major shifts will be taking place.</p>
    <p>At this point, some people will yell “But I don’t want to change! I’m happy with things as they are.” And for those people, unfortunately, I’ve got to be the bearer of bad news..</p>
    <p>This industry doesn’t work like that.</p>
    
    <h2>Changing Tides</h2>
    <p>Tech is a revolving door of changes.</p>
    <p>New languages come. “Groundbreaking” frameworks become the latest trend. Old languages become relics. You get the point.</p>
    <p>If you learn enough programming to get a job and then decide you don’t want to learn ever again, you’re going to have a rough time. Can it be done? Sure, some people do get by a few decades working with outdated tech at old companies.</p>
    <p>But, at the same time, the demand for FORTRAN and Pascal devs hasn’t really been on the rise lately.</p>
    <p>The people who find the most success in tech are those who regularly learn and move with the changing tides.</p>
    
    <h2>Whats Your Point?</h2>
    <p>Alright, alright. I know that “tech changes” is not some major revelation. But it’s still worth considering this intrinsic nature of our industry.</p>
    <p>These new changes with AI shouldn’t be something you dread. If you’re someone considering programming as a career, I still encourage you strongly to do so. And if you’re a current developer worried about future job security, don’t feel like your doom is inevitable.</p>
    <p>At the same time, don’t ignore the new breakthroughs in technology coming out. It’s crucial to your career that you pay attention and learn the new trends that arise. If you ignore these major shifts, you’ll undoubtedly get left behind by the industry.</p>
    <p>So for the owners of any horse carriage businesses out there, the world isn’t ending. Will you need to learn, adapt, and pivot with these new breakthroughs? Sure. But that’s honestly the way the world tends to work anyways. As the Italian writer Giuseppe Tomasi once said,</p>
    <p><i>“If we want things to stay as they are, things will have to change.”</i></p>
`;