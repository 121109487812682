export const NOT_LANDING_INTERVIEWS_CONTENT: string = `
    <p>You ask a group of programmers, “What’s the worst part of job searching?”. What do you think they’ll say?</p>
    <p>“Bombing a coding interview”</p>
    <p>“Getting passed up for another applicant”</p>
    <p>“Receiving an offer and its a lowball”</p>
    <p>Now, I won’t lie. I’ve been there, and these all majorly suck. But there’s another scenario that trumps them all:</p>
    <p>“Apply for hundreds of jobs and hear nothing back”</p>
    <p>All those hours spent searching and hardly any responses back. Talk about feeling defeated. And worst of all, 
    it’s <strong>incredibly</strong> common.</p>
    <p>But luckily, one simple change can have responses coming in.</p>
    
    <h2>Why Does Nobody Respond?</h2>
    <p>Alright, first things first: why the hell is nobody responding to you?</p>
    <p>Is it because you aren’t qualified? Is your resume just getting lost in massive piles of applicants? Or maybe hiring managers are just jerks who don’t value your time.</p>
    <p>Well… it’s often none of these.</p>
    <p>I’ve found one of the biggest reasons for people not hearing back is that their resume isn’t easy to read.</p>
    <p>(I know, I know. You’re probably thinking how simplistic and stupid that sounds. But just bear with me a second)</p>
    
    <h2>The 7 Second Test</h2>
    <p>Before I dive into my reasoning, let’s do a quick test.</p>
    <p>First, take out your resume.</p>
    <p>(And yes, you should really take it out right now and do this)</p>
    <p>You’re going to pretend that you’ve never seen your resume before. You don’t know your experience, your education, etc. You have no idea who you are. </p>
    <p>Now you’re going to set a timer on your phone for 7 seconds. When you hit go, you’ll start reading your resume with “fresh eyes”.</p>
    <p>I want you to genuinely see how much you can learn about yourself in those 7 seconds. What kind of experience do you have? What can you offer? Etc.</p>
    <p>Do this now and then come back.</p>
    <p><i>(Really! It’s worth it I promise)</i></p>
    
    <h2>The Results</h2>
    <p>How’d you do?</p>
    <p>If your resume is like most people’s, you probably gathered your name and that your objective is “I’d like a job”. Not exactly the best description of you, huh?</p>
    <p>Why does this little “7 Second Test” matter?</p>
    <p>Because according to sources like 
    <a href="https://www.indeed.com/career-advice/resumes-cover-letters/how-long-do-employers-look-at-resumes">Indeed</a>
     and 
     <a href="https://www.businessinsider.com/heres-what-recruiters-look-at-during-the-6-seconds-they-spend-on-your-resume-2012-4">Business Insider</a>
     , that’s how long hiring managers spend looking at your resume before deciding to go forward or not.</p>
     <p>You’ve got 7 seconds before they decide to keep learning more or to toss you aside.</p>
     <p>While that might seem a bit harsh, the recruiter isn’t brushing you aside out of spite. They often have so many applicants that it’s not reasonable to give each applicant a thorough look.</p>
     <p>That said, how do we make sure you pass the initial 7 seconds?</p>
     
     <h2>Tips for Improving Your Resume</h2>
     <p><strong>1. Order your resume sections (and bullets in the sections) by relevancy to the job.</strong></p>
     <ul>
        <li>Suppose you didn’t go to college, but have released several iOS apps. You would have a “Personal Projects” section way higher than your “Education” section.</li>
        <li>Let’s say you just graduated with a CS degree, but have no relevant work experience. You put your “Education” section above your “Work Experience” of delivering for Papa Johns.</li>
    </ul>
    
    <p><strong>2. Be concise. Like <i>really</i> concise.</strong></p>
    <ul>
        <li>If your resume looks like an essay, it’s not getting read (yes, even if you did it in MLA format).</li>
        <li>Each item on your resume (a job, a school, etc) should have around 5 bullets max that briefly describe it.</li>
    </ul>
    
    <p><strong>3. Don’t waste resume space on generic things.</strong></p>
    <ul>
        <li>Don’t put an “Objective” section (I mean, we all know your objective is to get a job).</li>
        <li>Don’t waste space describing yourself as “hard working, a quick learner, etc”. It might be true, but if anyone can put it on their resume, it doesn’t hold much weight.</li>
    </ul>
    
    <p><strong>4. Keep it one page.</strong></p>
    <ul>
        <li>If you’re a newer dev and feel its hard to keep things one page, your not being nearly concise enough.</li>
        <li>Once you have ~10 years of experience (or worked at ~5 or so jobs), you can decide on adding a second page.</li>
    </ul>
    
    <p><strong>5. Unfortunately, Buzzwords matter.</strong></p>
    <ul>
        <li>Every tech role has a list of technologies in the job posting. Make sure your resume mentions any relevant ones you have experience with.</li>
        <li>A “Skills” section at the bottom can be a great place for developers to list what they’ve worked with. That way you don’t bloat the rest of your resume with “buzzwords”.</li>
    </ul>
    
    <h2>Conclusion</h2>
    <p>Now, I can’t solve everyone’s resume in a short article. There’s an endless number of reasons why someone’s resume might not be working.</p>
    <p>That said, I certainly think the general tips above can help you. After all, if your resume isn’t easily readable, none of your other potential issues even matter.</p>
    <p>Now, if you want a step by step guide to making a great resume, I happen to have a course that covers just that! (How convenient, I know 😉).</p>
    <p>But even if you don’t take the course, I really hope this article helps you improve your resume and earn more responses from recruiters.</p>
    <p>And finally, best of luck on your job search!</p>
`;