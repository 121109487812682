import React from "react";
import "../css/MembershipOverviewModule.css";
import {MEMBERSHIP_OVERVIEW_ITEMS} from "../constants/MembershipOverviewConfig";

export const MembershipOverviewModule = () => {
    return (
        <div className={'membership-overview-module-container'}>
            <h3>Made For Any Developer,<br/>From Any Programming Language</h3>
            <div className="membership-overview-module-content">
                {MEMBERSHIP_OVERVIEW_ITEMS.map((item, index) => (
                    <div key={index} className={`overview-item`}>
                        <div className="overview-icon">
                            <img src={item.icon} alt={`${item.header} icon`} />
                        </div>
                        <div className="overview-text">
                            <h4>{item.header}</h4>
                            <p>{item.subtitle}</p>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};