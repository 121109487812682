import React from 'react';
import "../css/GenericPage.css"
import {trackEvent, TRACKING_EVENTS} from "../util/TrackingHelper";

export const GoPremiumButton: React.FC<any> = ({isAuthenticated, setShowAuth, setShowPremiumOverlay, page, sectionName}) => {
    const onButtonClick = () => {
        if (!isAuthenticated) {
            setShowAuth(true)
            trackEvent({page: page, event: TRACKING_EVENTS.JOIN_NOW, section: sectionName})
        } else {
            setShowPremiumOverlay(true)
            trackEvent({page: page, event: TRACKING_EVENTS.GO_PREMIUM, section: sectionName})
        }
    }
    if (!isAuthenticated) {
        return (<button className="go-premium-button" onClick={() => onButtonClick()}>Join Now</button>);
    } else {
        return (<button className="go-premium-button" onClick={() => onButtonClick()}>Go Premium</button>);
    }
};

export const LogInButton: React.FC<any> = ({setShowAuth, page}) => {
    const onLoginButtonClick = () => {
        setShowAuth(true)
        trackEvent({page: page, event: TRACKING_EVENTS.LOG_IN})
    }
    return (
        <button className="log-in-button" onClick={() => onLoginButtonClick()}>Log In</button>
    );
};