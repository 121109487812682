export const COVER_LETTER_FOR_TECH_JOB_CONTENT: string = `
    <p>Let’s be honest. Job searching these days can be awful.</p>
    <ul>
        <li>You find a job to apply for.</li>
        <li>You upload your resume.</li>
        <li>You then manually enter all the information that is already on your resume.</li>
        <li>You submit (and probably never hear back).</li>
    </ul>
    <p>And then on top of it, they all ask you for a personalized letter. Seriously?</p>

    <h2>Are Cover Letters Actually Read?</h2>

    <p>One of my favorite features on BeyondCode is our Expert Advice.
    I surveyed people all across my network in tech asking them their honest opinion on many different topics.</p>
    <p><strong>Question: Are cover letters required?</strong> <br>No</p>
    <p><strong>Question: Do you read cover letters?</strong> <br>Yes</p>
    <p>According to a recent survey from Resume Genius, 83% of hiring managers read most cover letters they receive. 
    83%!</p>
    <p>Alright, alright fine – cover letters are usually read. But are they actually helpful and worth the effort to 
    write?</p>
    
    <h2>Standing Out As An Applicant</h2>
    <p>Let’s think about the application process.</p>
    <p>For each job you apply to, you’re actively competing with every other applicant.
    (Very revolutionary insight, I know)</p>
    <p>But given that we’re constantly competing, we need ways to stand out whenever possible. </p>
    <p>Obviously, your resume the main tool for distinguishing yourself prior to interviews. The only issue is they 
    usually lack personality.</p>
    <p>The cover letter on the other hand gives us a brief chance to be personable. We can introduce who we are, why 
    we’re interested in the company, and the value we can bring them.</p>
    <p>On top of that, cover letters also show that you care enough about the job to go out of your way and submit a 
    letter. If you were equally tied with another candidate based on resumes, a well written letter might give you 
    that slight edge.</p>
    
    <h2>The Secret to Writing Quick Cover Letters</h2>
    <p>So as I’m sure you can guess, I strongly urge candidates to take the time to submit cover letters 
    (<strong>especially</strong> if you’re applying to a company you’re really interested in).</p>
    <p>Now I know many of you are ready to scoff and tell me the application process is bad enough without having to 
    pretend you’re Mark Twain each time. <strong>I agree with you.</strong></p>
    <p>While I think you should submit cover letters, I don’t think you should write new ones each time. That’d be an 
    insane ask of someone.</p>
    <p>That said, I also don’t think a completely reusable cover letter does much for you. It’s pretty obvious from a 
    hiring manager’s perspective if it’s just a copy-paste letter.</p>
    <p>The secret is learning how to create a mostly reusable letter that you are able to cater 
    slightly.</p>
    <p>You’ll want to spend time creating a base cover letter that will be ~80% reusable. I usually do a small 
    paragraph introducing myself, a small paragraph talking about the company, and a conclusion sentence.</p>
    <p>Then as you apply to different jobs, you can add just a few small details that show you’ve catered the letter 
    to this company.</p>
    
    <h2>Real Example</h2>
    <p>To help demonstrate my point, I’ve taken two sentences from the actual cover letter I used when applying to LinkedIn.</p>
    <p>In order to show how it’s reusable, I’ve highlighted it in the following colors:</p>
    <ul>
        <li><strong style="color:green;">Green:</strong> Shouldn’t Change</li>
        <li><strong style="color:#ff9c2e;">Yellow:</strong> Might Change</li>
        <li><strong style="color:red;">Red:</strong> Should Change</li>
    </ul>

    <p>First, let’s look at a sentence from my intro:</p>
    <p><span style="color:green;">I recently discovered your job opening for an Android Developer.</span> <span style="color:green;">I am currently a Senior Mobile Developer</span> <span style="color:#ff9c2e;">for a company that provides mobile development solutions for high end clients such as</span> <span style="color:red;">[Client 1]</span> <span style="color:green;">and</span> <span style="color:red;">[Client 2]</span>.</p>

    <p>As you can see, almost nothing here changes. The only possible thing would be if I apply for a job with a different title.</p>

    <p>Now, let’s look at a sentence where I discuss the company:</p>
    <p><span style="color:green;">I noticed that your company has a mission to</span> <span style="color:red;">provide economic opportunity for all members of the global workforce</span>. <span style="color:green;">I find this to strongly align with my own passion for</span> <span style="color:red;">creating software that provides opportunity for people in need</span>.</p>

    <p>In this case, I simply swap out the company’s specific values into the first red block. Then I mention why I align with those values in the second red block. <span style="color:#ff9c2e;">I might need to update the parts in yellow (like if they say “Company Values” instead of “Company Mission”)</span>, but it’s not too often.</p>

    <p>All in all, this process of tweaking my cover letter doesn’t take much extra time. I can look up some info on the company, swap out some words, and submit the cover letter in under 5 minutes.</p>
    
    
    <h2>Conclusion</h2>
    <p>While cover letters aren’t a requirement, I am of the opinion that applicants should use everything at their disposal to stand out. I think they provide a chance to show a bit more personality and interest in the company.</p>
    <p>While writing cover letters from scratch each time would be insane, a mostly reusable cover letter is a quick and effective way to stand out.</p>
`;