import CoursesOverviewIcon from "../images/Courses-Overview.webp";
import InterviewsOverviewIcon from "../images/Interviews-Overview.webp";
import ResourcesOverviewIcon from "../images/Resources-Preview.webp";
import CommunityOverviewIcon from "../images/Discord-Overview.webp";

export const MEMBERSHIP_OVERVIEW_ITEMS = [
    {
        header: "Courses",
        subtitle: "Our courses focus on helping you build your career. Whether it's writing a resume, preparing for tech interviews, or just looking for a starting place, we've got you covered.",
        icon: CoursesOverviewIcon
    },
    {
        header: "Interviews",
        subtitle: "Learn from successful software engineers who were once in your shoes. Hear how an elementary teacher transitioned to a coding career, how a community college student landed a job in Big Tech, and more.",
        icon: InterviewsOverviewIcon
    },
    {
        header: "Resources",
        subtitle: "Building your tech career can be challenging, but our resources can make your life easier. Programming study guides, example tech resumes, coding cheat sheets, and more! We've got you covered.",
        icon: ResourcesOverviewIcon
    },
    {
        header: "Community",
        subtitle: "This isn't just a learning platform - it's a community. All members are invited to join our Discord where we actively discuss questions, advice, and more! Nothing makes the journey easier than doing it alongside others.",
        icon: CommunityOverviewIcon
    }
]