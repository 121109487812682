import React from "react";
import "../css/Footer.css";
import {FOOTER_CONFIG} from "../constants/FooterConfig";
import LogoMedium from "../images/Logo_Medium.webp";

export const Footer = () => {
    return (
        <div className="footers-module-container">
            <img className={"footer-logo"} src={LogoMedium} alt={"Beyond Code Logo"} />
            <div className={"contact-us-container"}>
                <h4>{FOOTER_CONFIG.contactUsHeader}</h4>
                <div>{FOOTER_CONFIG.contactUsContent}</div>
            </div>
            <div className={"legal-text-container"}>
                <p>{FOOTER_CONFIG.allRights}</p>
                <a href={FOOTER_CONFIG.privacyUrl}>{FOOTER_CONFIG.privacyText}</a>
                <a href={FOOTER_CONFIG.termsUrl}>{FOOTER_CONFIG.termsText}</a>
            </div>
        </div>
    );
};