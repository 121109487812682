import React, {useState} from "react";
import {Advice} from "../models/Advice";
import "../css/AdviceSection.css"
import {ICONS} from "../util/IconHelper";
import {trackEvent} from "../util/TrackingHelper";

export const AdviceSection: React.FC<{ adviceList: Advice[], page: string }> = ({adviceList, page}) => {
    const [activeQuestionIndex, setActiveQuestionIndex] = useState<number | null>(
        null
    );
    const [activeAnswerIndex, setActiveAnswerIndex] = useState(0);

    const handleQuestionClick = (index: number, advice: Advice) => {
        trackEvent({event: advice.trackingId, page: page})
        setActiveQuestionIndex(index === activeQuestionIndex ? null : index);
        setActiveAnswerIndex(0); // Reset to first answer when a new question is opened
    };

    const handlePrevAnswer = () => {
        if (activeQuestionIndex !== null) {
            const currentAnswers = adviceList[activeQuestionIndex].answers;
            setActiveAnswerIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : currentAnswers.length - 1
            );
        }
    };

    const handleNextAnswer = () => {
        if (activeQuestionIndex !== null) {
            const currentAnswers = adviceList[activeQuestionIndex].answers;
            setActiveAnswerIndex((prevIndex) =>
                prevIndex < currentAnswers.length - 1 ? prevIndex + 1 : 0
            );
        }
    };

    const renderDots = () => {
        if (activeQuestionIndex === null) return null;
        const currentAnswers = adviceList[activeQuestionIndex].answers;

        return (
            <div className="advice-dots">
                {currentAnswers.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === activeAnswerIndex ? "active" : ""}`}
                        onClick={() => setActiveAnswerIndex(index)}
                    ></span>
                ))}
            </div>
        );
    };

    return (
        <div className="advice-container">
            {adviceList.map((advice, index) => (
                <div key={index} className="advice-item">
                    <div
                        className="advice-question"
                        onClick={() => handleQuestionClick(index, advice)}
                    >
            <span className={"advice-open-toggle-text"}>
              {activeQuestionIndex === index ? "-" : "+"}
            </span>
                        {advice.question}
                    </div>
                    {activeQuestionIndex === index && (
                        <div className="advice-answer-container">
                            <div className="advice-answer">
                                <p>{advice.answers[activeAnswerIndex].answerText}</p>
                                <div className="advice-person">
                                    <strong className={"advice-person-name"}>
                                        {advice.answers[activeAnswerIndex].advicePerson.name}
                                    </strong>
                                    <p>
                                        {advice.answers[activeAnswerIndex].advicePerson.position} @{" "}
                                        {advice.answers[activeAnswerIndex].advicePerson.company}
                                    </p>
                                </div>
                            </div>
                            <div className="advice-carousel">
                                <ICONS.LEFT_ARROW onClick={handlePrevAnswer} />
                                <ICONS.RIGHT_ARROW onClick={handleNextAnswer} />
                            </div>
                            {renderDots()}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};