// Firebase.tsx
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import React, {useEffect } from "react";
import "firebaseui/dist/firebaseui.css";
import { GoogleAuthProvider, EmailAuthProvider, getAuth, UserCredential } from "firebase/auth";
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import "../css/Firebase.css";
import LogoMedium from "../images/Logo_Medium.webp";
import { ICONS } from "./IconHelper";
import {Page, trackEvent, TRACKING_EVENTS} from "./TrackingHelper";

const firebaseConfig = {
    apiKey: "AIzaSyBqeFOCVZfmfOqM0aMFsESYuKUpBCLVkLY",
    authDomain: "beyondcode-70c30.firebaseapp.com",
    projectId: "beyondcode-70c30",
    storageBucket: "beyondcode-70c30.appspot.com",
    messagingSenderId: "751029928365",
    appId: "1:751029928365:web:9df79853d432cabcb32124",
    measurementId: "G-KL9MF81ZML"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = (process.env.REACT_APP_ENV === 'production') ? getAnalytics(firebaseApp) : null;
export const storage = getStorage(firebaseApp);
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);

interface FirebaseAuthProps {
    setShowAuth: (show: boolean) => void;
}

export const FirebaseAuth: React.FC<FirebaseAuthProps> = ({ setShowAuth }) => {
    useEffect(() => {
        const uiConfig = {
            signInFlow: 'popup',
            signInOptions: [
                GoogleAuthProvider.PROVIDER_ID,
                EmailAuthProvider.PROVIDER_ID
            ],
            callbacks: {
                signInSuccessWithAuthResult: (userCredentials: UserCredential) => {
                    const user = userCredentials.user;
                    if (user.metadata.lastSignInTime === user.metadata.creationTime) {
                        trackEvent({ event: TRACKING_EVENTS.USER_SIGN_UP, page: Page.SIGN_UP_POPUP });
                    } else {
                        trackEvent({ event: TRACKING_EVENTS.USER_SIGN_IN, page: Page.SIGN_UP_POPUP });
                    }
                    if (user) {
                        setDoc(doc(firestore, "users", user.uid), {
                            uid: user.uid,
                            email: user.email,
                            name: user.displayName || 'Anonymous',
                            provider: user.providerData[0].providerId,
                            photoUrl: user.photoURL || null,
                        }, {merge: true}).catch((error) => {
                            console.error("Error saving user data to Firestore: ", error);
                        });
                    }
                    return false;
                }
            }
        };

        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

        ui.start('#firebaseui-auth-container', uiConfig);

        return () => {
            ui.reset();
        };
    }, []);

    return (
        <div className={"firebase-auth-background"} onClick={() => setShowAuth(false)}>
            <div className={"firebase-auth-modal"} onClick={(e) => e.stopPropagation()}>
                <ICONS.CLOSE className="auth-container-close" onClick={() => setShowAuth(false)} />
                <img className={"header-logo"} src={LogoMedium} alt={"Beyond Code Logo"} />
                <div id="firebaseui-auth-container"></div>
            </div>
        </div>
    );
};

export default FirebaseAuth;