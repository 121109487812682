import React from "react";
import "../css/TestimonialsModule.css";
import { FaStar } from "react-icons/fa";
import {TESTIMONIALS_CONFIG} from "../constants/TestimonialsConfig";

export const TestimonialsModule = () => {
    return (
    <div className="testimonials-module-container">
            <h4>{TESTIMONIALS_CONFIG.header}</h4>
            <div className={"testimonials-items-container"}>
                {TESTIMONIALS_CONFIG.items.map((item, index) => (
                    <div className={"testimonial-item-container"} key={index}>
                        <div className={"testimonial-user-container"}>
                            {item.avatar && (
                                <img src={item.avatar} alt={`${item.name} avatar`}/>
                            )}
                            <div className={"testimonial-user-name-container"}>
                                <h4>{item.name}</h4>
                                <h5>{item.specialty}</h5>
                            </div>
                        </div>
                        <p>{item.reviewText}</p>
                        <div className={"testimonial-rating-container"}>
                            <p>Rating:</p>
                            {Array(item.rating).fill(null).map((_, i) => (
                                <FaStar key={i} className="testimonial-star-icon" />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};