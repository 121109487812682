import React from "react";
import "../css/Header.css";
import LogoMedium from "../images/Logo_Medium.webp";
import {LEARNING_PATHS} from "../constants/LearningPathsConfig";
import { RiAccountBoxFill } from "react-icons/ri";
import {GoPremiumButton, LogInButton} from "./GoPremiumButton";
import {ICONS} from "../util/IconHelper";
import {useNavigate} from "react-router-dom";
import {EVENTS, Page, trackEvent, TrackingEvent} from "../util/TrackingHelper";

export const Header: React.FC<any> = ({setShowAuth, isAuthenticated, setShowHamburger, setShowPremiumOverlay}) => {
    const navigate = useNavigate()
    const onHeaderLinkClicked = (navUrl: string, trackingEvent: TrackingEvent) => {
        navigate(navUrl)
        trackEvent(trackingEvent)
    }

    return (
        <div className="header-module-container">
            <img className={"header-logo"} src={LogoMedium} alt={"Beyond Code Logo"} onClick={() => onHeaderLinkClicked("/", EVENTS.HEADER.HEADER_LOGO)}/>
            <div className={"header-links-container"}>
                {LEARNING_PATHS.map((item, index) => (
                    <div className={"header-link-content"} key={item.navUrl}>
                        <div className={"header-link"} onClick={() => onHeaderLinkClicked(item.navUrl, item.headerClickEvent)}>{item.header}</div>
                        {index < LEARNING_PATHS.length - 1 && (
                            <span className="header-divider">|</span>
                        )}
                    </div>
                ))}
            </div>
            {isAuthenticated && <RiAccountBoxFill className={"header-account-icon"} onClick={() => onHeaderLinkClicked("/account", EVENTS.HEADER.HEADER_ACCOUNT)}/>}
            {!isAuthenticated && (
                <div className={'header-logged-out-buttons'}>
                    <div className={'header-logged-out-web-container'}>
                        <LogInButton setShowAuth={setShowAuth} page={Page.HEADER}/>
                        <GoPremiumButton isAuthenticated={isAuthenticated}
                                         setShowAuth={setShowAuth}
                                         setShowPremiumOverlay={setShowPremiumOverlay}
                                         page={Page.HEADER}
                        />
                    </div>
                    <ICONS.HAMBURGER className="header-mobile-hamburger" onClick={() => setShowHamburger(true)}/>
                </div>
            )}
        </div>
    );
};