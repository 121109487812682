export class Advice {
    question: string;
    answers: AdviceAnswer[];
    trackingId: string;

    constructor(question: string, answers: AdviceAnswer[], trackingId: string) {
        this.question = question;
        this.answers = answers;
        this.trackingId = trackingId;
    }
}

export class AdviceAnswer {
    answerText: string;
    advicePerson: AdvicePerson;

    constructor(answerText: string, advicePerson: AdvicePerson) {
        this.answerText = answerText;
        this.advicePerson = advicePerson;
    }
}

export class AdvicePerson {
    name: string;
    position: string;
    company: string;

    constructor(name: string, position: string, company: string) {
        this.name = name;
        this.position = position;
        this.company = company;
    }
}