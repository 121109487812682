import React from "react";
import "../css/MobileHamburgerMenu.css"
import {LEARNING_PATHS} from "../constants/LearningPathsConfig";
import {GoPremiumButton, LogInButton} from "./GoPremiumButton";
import {ICONS} from "../util/IconHelper";
import {useNavigate} from "react-router-dom";
import {EVENTS, Page, trackEvent, TrackingEvent} from "../util/TrackingHelper";

export const MobileHamburgerMenu:React.FC<any> = ({setShowHamburger, isAuthenticated, isPremium, setShowAuth, setShowPremiumOverlay}) => {
    const navigate = useNavigate()

    const onHamburgerMenuClick = (navUrl: string, trackingEvent: TrackingEvent) => {
        navigate(navUrl)
        trackEvent(trackingEvent)
    }
    return (
        <div className={'mobile-hamburger-container'} onClick={() => setShowHamburger(false)}>
            <div className={'mobile-hamburger-content'}>
                <ICONS.CLOSE className={'hamburger-close-icon'} />
                <div className={'hamburger-options'}>
                    <div className={'hamburger-log-in-and-join-container'}>
                        {!isAuthenticated && <LogInButton setShowAuth={setShowAuth} page={Page.MOBILE_HEADER}/>}
                        {!isPremium && <GoPremiumButton isAuthenticated={isAuthenticated}
                                                        setShowAuth={setShowAuth}
                                                        setShowPremiumOverlay={setShowPremiumOverlay}
                                                        page={Page.MOBILE_HEADER}
                        />}
                    </div>
                    <div className={"hamburger-header-link"} onClick={() => onHamburgerMenuClick("/", EVENTS.HEADER.HEADER_HOME)}>Home</div>
                    {LEARNING_PATHS.map((item, index) => (
                        <div key={index} onClick={() => onHamburgerMenuClick(item.navUrl, item.headerClickEvent)} className={"hamburger-header-link"}>{item.header}</div>
                    ))}
                    {isAuthenticated && <div onClick={() => navigate("/account")} className={"hamburger-header-link"}>Account</div>}
                </div>
            </div>
        </div>
    )
}